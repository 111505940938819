.carousel {
  $el: &;

  .slick-list,
  .slick-track {
    min-width: 100%;
  }

  .slick-track {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-bottom: 24px;
  }
  .slick-slide {

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .slick-slide {
    height: auto;
  }

  .slick-dots {
    bottom: 0px;
  }

  &--container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
  }

  &--size {
    height: 100%;
  }

  &--arrow {
    display: flex;
    height: 2rem;
    width: 2rem;
    background: $background !important;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);

    svg {
      font-size: 13px;
      color: var(--scrollbar-color);
    }

    &:before {
      display: none;
    }
  }

  &--left {
    left: 4px;
    z-index: 999;
    @include breakpoints(sm) {
      left: 5px;
    }
  }

  &--right {
    right: 4px;
    z-index: 999;
    @include breakpoints(sm) {
      right: 5px;
    }
  }
}
