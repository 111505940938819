.style-bar {

    &--container {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        margin-top: 8px;
        padding-left: 16px;
        padding-right: 16px;
        min-width: 0;
        height: 10%;
        scroll-behavior: smooth;
        z-index: 1;

        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &--element {
        cursor: pointer;
        display: flex;
        flex: none;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        width: 20%;
        position: relative;
        overflow: hidden;

        @include breakpoints(mdl) {
            width: 10%;
        }

        &:nth-of-type(1) {
            margin-left: auto;
        }

        &:nth-last-of-type(1) {
            margin-right: auto;
        }

        &:hover {
            transform: scale(1.1);
            // ---- Fix overflow when scaling ----
            height: 98%;
        }
    }

    &--title {
        background-color: #fff9;
        width: 100%;
        text-align: center;
        font-size: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        z-index: 1;
    }

    &--arrow-container {
        position: absolute;
        cursor: pointer;
        z-index: 2;
        background-color: $lightcolor--lighter;
        height: 25px;
        width: 25px;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
    }

    &--arrow-container-left {
        @extend .style-bar--arrow-container;

        left: 8px;
    }

    &--arrow-container-right {
        @extend .style-bar--arrow-container;

        right: 8px;
    }

    &--icon-container {
        height: 80%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }

    &--icon-container--hidden {
        @extend .style-bar--icon-container;
        background-color: #fff9;
    }

    &--checkbox {
        .ant-checkbox {
            box-shadow: none;
        }

        .ant-checkbox-checked:after {
            border: none;
        }

        .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled,
        .ant-checkbox-disabled .ant-checkbox-input {
            cursor: pointer !important;
        }
    }

    &--touch-div {
        width: 26px;
        height: 27px;
        position: absolute;
        top: 0;
        right: 0;
    }

    &--subheader {
        min-width: 0;
        width: 100%;
    }

    &--subheader-container {
        background-color: $lightcolor--lighter
    }

    &--filter-container {
        @extend .button--filter;

        margin: 0;
        padding-right: 16px;
        padding-left: 8px;
        background-color: $lightcolor--lighter;

    }

    &--image-container {
        position: absolute;
        z-index: 0;
    }
}