.layout {
    $el: &;

    $header-height: 40px;
    $header-height-mobile: 34px;
    $sub-header-height: 34px;

    &--content {
        display: flex;
        flex-direction: column-reverse;
        flex: 1;
        height: 100%;
        overflow-y: auto;

        @include breakpoints(sm) {
            flex-direction: row;
        }
    }

    &--container {
        background-color: $lightcolor;
        height: auto;
        flex:1;
        width: 100%;
    }

    &--header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        z-index: 2;
        height: auto;
        width: 100%;
        padding: 0px;
        line-height: initial;
        border-bottom: 1px solid $lightcolor;

        .layout--page-swipe &,
        .layout--page-product &,
        .layout--page-model & {
            border-bottom: none;

            @include breakpoints(sm) {
                border-bottom: 1px solid $lightcolor;
            }
        }
    }

    &--header-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;

        >:first-child {
            margin-left: 16px;
        }

        >:nth-child(n) {
            margin-right: 16px;
        }
    }

    &--header-title {
        display: none;
        align-items: center;

        @include breakpoints(smd) {
            display: flex;
        }

        &-separator {
            border-left: 1px solid $lightcolor;
        }
    }

    &--header-main-container {
        width: 100%;
        overflow: hidden;
    }

    &--header-top-container {
        display: flex;
        flex-direction: column-reverse;

        @include breakpoints(sm) {
            flex-direction: row;
        }
    }

    &--header-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        min-height: $header-height-mobile;

        border-bottom: 1px solid $lightcolor;

        overflow-x: auto;

        @include breakpoints(sm) {
            border-bottom: none;
            overflow-x: unset;
        }
    }

    &--header-button-container {
        display: flex;
        flex: 5;
        justify-content: flex-end;
        gap: 16px;
        padding-right: 16px;
    }

    &--header-button-container-mobile {
        @extend .layout--header-button-container;

        gap: 0px;

        @include breakpoints(xxxsm) {
            gap: 8px;
        }
    }

    &--header-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        min-height: $header-height-mobile;
        height: 100%;
        width: 100%;
        overflow-x: hidden;

        .layout--page-model &,
        .layout--page-swipe &,
        .layout--page-product & {
            display: none;
            @include breakpoints(sm) {
                display: flex;
            }
        }

        .ant-tabs-top {
            height: 100%;
            min-width: 80px;
        }

        .ant-tabs-top .ant-tabs-nav {
            height: 100%;
            margin: 0;
        }

        @include breakpoints(sm) {
            min-height: $header-height;
        }
    }

    &--header-left-content {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        overflow: hidden;
    }

    &--header-left-content-menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;
        height: $header-height-mobile;
        justify-content: space-between;
        min-width: 0;

        @include breakpoints(sm) {
            height: $header-height;
            justify-content: flex-start;
        }

        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
            text-shadow: 0 0 1px currentColor;
        }
    }

    &--header-filter-button {
        width: 30px;
        height: 30px;
        font-size: 30px;
        line-height: 30px;
        margin-right: -4px;
        padding: 0;

        &.ant-btn.ant-btn-icon-only {
            width: 30px;
        }

        .anticon {
            font-size: 30px !important;
            width: 100%;
        }
    }

    &--header-burger-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px !important;
        height: 25px;
        padding: 0;
        margin-left: 16px;
        margin-right: 8px;

        .ant-btn-icon {
            justify-content: center;
        }

        .ant-btn-icon .anticon {
            font-size: 20px;
        }
    }

    &--header-filter-label {
        margin-left: 8px;
        display: none;

        @include breakpoints(smd) {
            display: block;
        }
    }

    &--swipe-content {
        align-items: center;
        justify-content: center;
        user-select: none;
        height: 100%;

        @include breakpoints(sm) {

            .layout--title {
                display: none;
            }
        }
    }

    &--right-content {
        flex: 1;
        display: none;
        background-color: $lightcolor--lighter;

        @include breakpoints(sm) {
            display: block;
            overflow-y: auto;
            padding: 16px 16px 0;

            .layout--title {
                display: none;
            }
        }
    }

    &--left-content {
        padding: 16px 32px 0;
        background-color: white;
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow-y: auto;

        .layout--page-swipe & {
            padding: 16px 0 0;

            @include breakpoints(sm) {
                padding: 16px 32px 0;
            }
        }

        .layout--page-model & {
            padding: 0;

            @include breakpoints(sm) {
                flex: 2;
            }

            .model--container {
                @include breakpoints(sm) {
                    flex: 2;
                }
            }
        }
    }

    &--left-overlay {
        @extend .layout--left-content;

        position: relative;
        padding: 0 !important;
    }

    &--side-left {
        background-color: #f7f7fa;
    }

    &--side-right {
        background-color: $background;
    }

    &--title {
        display: none;

        @include breakpoints(sm) {
            display: flex;
        }

        min-height: 0 !important;
    }

    &--headroom {
        background-color: #fff;
        padding: 16px 16px 0;

        @include breakpoints(sm) {
            display: none;
        }

        .sticky {
            top: 65px !important;
            z-index: 1;
        }
    }

    &--poweredby-mobile {
        @include breakpoints(sm) {
            display: none;
        }
    }

    %powered-by {
        color: rgba(0, 0, 0, .25);
        text-align: center;
        margin-top: 32px;
        padding-bottom: 8px;
        font-size: 12px;

        a {
            text-decoration: underline;
            color: rgba(0, 0, 0, .25);
        }
    }

    &--poweredby-mobile {
        @extend %powered-by;

        .layout--page-model & {
            margin-top: 0;
        }

        @include breakpoints(sm) {
            display: none;
        }
    }

    &--poweredby-desktop {
        @extend %powered-by;
        display: none;

        @include breakpoints(sm) {
            display: block;
        }
    }

    &--toggleview {
        position: absolute;
        right: 0;
        top: 16px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        background-color: white;
        z-index: 1000;
    }
}