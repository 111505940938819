.favorites {
    $el: &;

    &--container {
        height: auto;
    }

    &--pane {
        display: flex;
        width: 100%;
        justify-content: center;

        @include breakpoints(mdl) {
            display: none;
        }
    }

    &--space {
        height: 52px;
        width: 100%;
    }

    &--look-detail {
        width: 100%;
        text-align: center;
    }
}

.ant-tabs-nav::before {
    border: none !important;
}