.product {
  $el: &;
  position: relative;

  &--container {
    padding: 10px;
    height: 100%;
    @include breakpoints(sm) {
      margin-top: 35px;
      margin-bottom: 35px;
    }
  }

  &--bottom {
    display: flex;
    min-height: 100px;
    height: 100px;
    width: 100%;
  }

  &--brand {
    @extend %title-h2;
    font-weight: normal !important;
  }

  &--title {
    @extend %title-h2;
  }

  &--price {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 16px;
  }

  &--price--promotion {
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 0;
  }

  &--price--original {
    @extend .product--price;

    text-decoration: line-through;
  }

  &--price--percent {
    @extend .product--price;

    margin-left: 12px;
  }

  &--price--final {
    @extend .product--price;
  }

  &--text {
    @extend %text-base;
    font-size: 13px;
    margin-bottom: 30px;

    @include breakpoints(sm) {
      max-width: 80%;
    }
  }

  &--bottom-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 8px;
    column-gap: 8px;

    @include breakpoints(sm) {
      justify-content: flex-start;
    }

    .ant-select {
      width: 247px;
    }

    button, a {
      @extend %button-medium-base;
      width: 247px;
    }
  }

  &--list {
    margin-bottom: 20px;
    padding: 0;
    padding-left: 20px;

    li {
      @extend %text-base;
      font-size: 13px;
      line-height: 18px;
    }
  }

  &--left-side {
    @include breakpoints(mdl) {
      padding-right: 16px;
    }
  }

  &--right-side {
    display: flex;
    flex-direction: column;
  }

  &--extra-product {
    padding: 0;
    margin-bottom: 16px; 
  }
}
