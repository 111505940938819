.tabs {
  $el: &;

  &--container {
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    z-index: 999;

    @include breakpoints(mdl) {
      display: none;
    }
  }

  &--mobile {
    @include breakpoints(sm) {
      display: none;
    }
  }

  &--icon-header {
    svg,
    path {
      fill: var(--scrollbar-color);
    }
  }

  &--sticky {
    z-index: 9999;
    & > div {
      z-index: 9999;
    }
  }

  &--icon {
    margin-bottom: 16px;
    cursor: pointer;
    svg,
    path {
      fill: var(--scrollbar-color);
    }
  }
}
