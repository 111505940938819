.button {
    $el: &;

    &--mobile {
        @include breakpoints(sm) {
            display: none !important;
        }
    }

    &--desktop {
        @include mx_breakpoints(sm) {
            display: none !important;
        }
    }

    &--outlined {
        @extend %button-medium-base;
        border: 1px solid;
        border-radius: 8px;
        padding: 8px 12px 8px 8px;
    }

    &--header {
        border: none;
        box-shadow: none;
        border-radius: 8px;
        font-weight: bold;
    }

    &--default {
        @extend %button-medium-base;
        border: 1px solid;
        border-radius: 8px;
        padding: 8px 12px 8px 8px;

        &>span {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
        }
    }

    &--underlined {
        display: inline-block;
        cursor: pointer;
        text-decoration: underline;
        padding: 0 10px 0 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: var(--primary-color);;
    }

    &--icon {
        padding: 0 !important;
        margin: 0 !important;
        border: none;
        box-shadow: none;

        .ant-btn-icon {
            display: flex;
            justify-content: center;
        }
    }

    &--icon:after {
        display: none !important;
        opacity: 0 !important;
    }

    &--icon-left {
        @extend .button--icon;

        >span {
            justify-content: flex-start !important;
        }
    }

    &--icon-right {
        @extend .button--icon;

        >span {
            justify-content: flex-end !important;
        }
    }

    &--exit-experience {
        @extend .button--icon;

        margin-left: 16px !important;
        margin-right: 8px !important;

        >span {
            font-size: 20px;
        }

        @include breakpoints(sm) {
            >span {
                font-size: 24px;
            }
        }
    }

    &--text {
        @extend %text-vsmall;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        padding: 0;
        padding-right: 40px;

        .anticon-left {
            margin-right: 8px;
        }
    }

    &--space-r {
        margin-right: 8px;
    }

    &--third {
        width: 100%;
        overflow: hidden;

        @include breakpoints(sm) {
            width: auto;
            min-width: 125px;
        }
    }

    >span {
        display: table;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    &--change-model {
        width: 150px;
        font-size: 12px;
        position: absolute;
        left: 0;
        white-space: pre-line;
        color: var(--primary-color);

        @include breakpoints(sm) {
            font-size: 14px;
        }
    }

    &--change-model-first-time {
        border: 1px solid var(--scrollbar-color) !important;
        font-size: 12px;
        position: absolute;
        left: 8px;
        height: auto;
        white-space: pre-wrap;
        font-weight: bold;
        padding: 4px;
        width: 100px;

        transition: none;
    }

    &--change-model-left-first-time {
        left: -158px;
        width: 150px;
        
    }

    &--change-model-left {
        left: -150px;
        font-weight: bold;
    }

    &--clear {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
        color: grey;
        cursor: pointer;
        border: none;
        background: transparent !important;
        box-shadow: none;
    }

    &--main {
        border: none;

        &>span {
            font-weight: 600;
        }
    }

    &--card-main {
        width: 100%;
        border: none;

        &>span {
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
        }
    }

    &--filter {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 16px;
        margin-left: 8px;

        @include breakpoints(sm) {
            margin-right: 0;
            margin-left: 0;
        }

        .ant-badge-count {
            line-height: 13px;
            font-size: 10px;
            border: 1px solid black;
            color: black;
        }
    }

    &--drawer {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;
        background-color: transparent;
        border: none;
        box-shadow: none;
        text-align: start;
        padding: 0;
        font-size: 16px;

        &>span {
            margin-left: 0 !important;
        }
    }

    &--detail {
        @extend .button--card-main;

        margin-top: 8px;
    }

    &--switch-view {
        &-catalog {
            @extend %switch-view-default;

            .layout--page-catalog & {
                color: black;
            }
        }

        &-swipe {
            @extend %switch-view-default;

            .layout--page-swipe & {
                color: black;
            }
        }

    }

    &--favorite {
        width: 32px;
        padding: 0;
        >span:last-of-type {
            display: none;
        }

        @include breakpoints(sm) {
            width: auto;
            padding: 0px 8px;
            span:last-of-type {
                display: inline-block;
            }
        }

        .ant-btn-icon {
            margin-inline-end: 0 !important;
            @include breakpoints(sm) {
                margin-inline-end: 8px !important;
            }
        }
    }
    &--favorite:after {display: none;}

    &--close {
        .ant-btn-icon {
            display: flex;
            justify-content: center;
        }
    }
}

%switch-view-default {
    color: lightgray;

    :hover {
        transition: color 0.3s;
        color: black;
    }
}

%button-medium-base {
    border-radius: 8px;
    min-height: 40px;
}

%button-small-base {
    border-radius: 8px;

    min-height: 34px;
}

a.ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    line-height: 30px;
}

// Keep primary color when primary and disabled
.ant-btn-primary[disabled] {
    background-color: var(--primary-color);
}
.ant-btn-primary[disabled]:hover {
    background-color: var(--primary-color);
}

.ant-btn[ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
 }