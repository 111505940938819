.select {
  $el: &;

  .ant-select-selector,
  .ant-select-selection-placeholder,
  ant-select-item ant-select-item-option,
  .ant-select-selection-item {
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 24px;
    color: #1c1c1c;
  }

  .ant-select-arrow {
    color: #1c1c1c;
    svg {
      color: #1c1c1c;
    }
  }

  &--container {
    width: 100%;
    background: $background;
    box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
    border-radius: 0;
    border: none;

    .ant-select-selector {
      border-radius: 0px !important;
      border: none !important;
    }
  }

  &--text {
    @extend %text-base;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  &--option .ant-select-item-option-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &--option-extra {
    padding-left: 8px;
    color: gray;
    display: block;
    overflow: hidden;
  }

  &--experience-container {
    margin-left: 0px;
    margin-right: 0px;

    @include breakpoints(xxsm) {
        margin-left: 16px;
    }
  }

  &--experience {
    min-width: 100px;
    max-width: 170px;

    @include breakpoints(sm) {
        max-width: none;
    }

    .ant-select-selection-placeholder {
        color: black;
        font-weight: bold;
    }

    .ant-select-selector {
        box-shadow: none !important;

        @include mx_breakpoints(sm) {
            border: none !important;
        }
    }

    .ant-select-selection-item {
        font-weight: bold;
    }
  }
}

.ant-layout-content {
  position: relative;
}

.ant-select-item-option-selected {
    background-color: $lightcolor !important;
}