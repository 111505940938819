*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  overscroll-behavior-y: none;
  box-sizing: border-box;
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-family: $font-main;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  line-height: 1.5715;
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  $primary-antd: var(--scrollbar-color);
  background-color: white;
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

#root,
.App {
  height: 100%;
  width: 100%;
  display: flex;

  &--loading {
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
}

%custom-scrollbar {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
    margin-left: 6px;
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-clip: content-box;
    background-color: var(--scrollbar-color);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}

.ant-btn {
  border-radius: 0 !important;
}

.ant-btn-icon-only .ant-btn-icon {
    display: flex;
    justify-content: center;
}

.ant-btn > div {
    display: none;
  }

.ant-select-dropdown {
  border-radius: 0 !important;
}

.ant-checkbox-disabled .ant-checkbox-inner {
    border-color: #0005;
}

.flex-centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
