.model {
  $el: &;

  &--container {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &--title {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    padding-top: 8px;
  }

  &--content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
}

  &--empty {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
  }
}
