.title {
    $el: &;

    &--main {
        cursor: pointer;
        display: none;

        @include breakpoints(sm) {
            display: block;
        }
    }

    &--h1 {
        @extend %title-base;
        font-size: 24px !important;
        margin-bottom: 0;

        .layout--page-model & {
            font-size: 20px !important;
        }
    }


    %title-h2,
    &--h2 {
        @extend %title-base;
        font-size: 18px !important;
    }

    &--h3 {
        @extend %title-base;
        font-size: 14px !important;
        margin-top: 0 !important;
    }

    &--h4 {
        @extend %title-base;
        font-size: 12px !important;
    }

    &--center {
        text-align: center;
    }

    &--bottom {
        margin-bottom: 10px !important;
    }

    &--hide-overflow {
        display: none;

        @include breakpoints(smd) {
            display: block;
        }
    }
}

%title-base {
    font-weight: bold !important;

    ::first-letter {
        text-transform: uppercase;
    }
}

%title-h2 {
    @extend %title-base;
    font-size: 18px !important;
}