.card-model {
  $el: &;

  &--container {
    border: none;
    border-radius: 0;
    overflow: hidden;

    .ant-card-body {
      padding: 0;
    }

    &:hover {
      .card--image {
        filter: blur(3px);
        -webkit-filter: blur(3px);
      }
    }
  }

  &--image {
    width: 100%;
    height: auto;
  }

  &--image-container {
    position: relative;
  }

  &--background {
    position: relative;
    width: 100%; /* la largeur est definie par le parent */
    height: 0;
    padding-bottom: 125%; /* calcul de la hauteur en fonction de la largeur du parent */
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &--top {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 8px;
    right: 8px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &--bottom {
    position: absolute;
    bottom: 0;
    padding: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    svg {
      width: 25px;
      height: 25px;
    }
  }

  &--bottom-left {
    .image--dot-container {
      margin: 0 !important;
    }
  }

  &--bottom-center {
    flex: 1;
    padding: 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    font-size: 12px;
    color: gray;

    @include breakpoints(sm){
      display: none;
    }
  }

  &--bottom-right {
    align-items: flex-end;
    & > span {
      justify-content: flex-end !important;
    }
  }

  &--tooltip {
    padding: 0;

    a {
      text-decoration: underline;
    }
  }

  &--checkbox-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 8px;

    & > span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
  }
  &--checkbox-label {
    color: $darkcolor;
    font-size: 12px;

    &-disabled {
        color: rgba(0, 0, 0, .25);
    }
  }
}

.ant-tooltip .ant-tooltip-inner {
  background-color: #ebebed;
  color: $darkcolor;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
}

.ant-tooltip-arrow {
  color: #ebebed;
  --antd-arrow-background-color: #ebebed;
}
