.look {
    $el: &;

    &--head {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: 16px;
        justify-content: inherit;
    }

    &--container {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0;

        &-swipe {
            padding: 0 16px;

            @include breakpoints(sm) {
                padding: 0;
            }
        }
    }

    &--swipe-content {
        position: relative;
        display: flex;
        height: auto;
        width: 100%;
        justify-content: center;
    }

    &--content {
        display: flex;
        height: auto;
        width: 100%;
        justify-content: center;
        position: relative;
    }

    %switch-base {
        @extend %text-base;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        line-height: 15px;
        color: #8f90a6;
        margin-left: 12px;
    }

    &--switch {
        @extend %switch-base;

        &>span {
            margin-right: 8px;
        }

        &:last-child {
            margin-right: 12px;
        }

        &:only-child {
            margin: 0 auto;
        }

        .card-model--bottom-left & {
            color: black;
            margin-left: 8px;

            &.look--switch-disabled>span {
                opacity: .4;
            }
        }
    }

    &--switch-vertical {
        @extend %switch-base;
        flex-direction: column;
        gap: 8px;
    }

    &--switch--row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex: 1;
        flex-wrap: wrap;
    }

    &--button {
        width: 100%;
        min-height: 40px;
        border-radius: 8px;
        @extend %text-base;
        font-weight: 500 !important;
        font-size: 13px;
        line-height: 15px;
        margin-top: 16px;

        &-swipe {
            @include breakpoints(sm) {
                display: none;
            }
        }

    }

    &--model-button-container {
        position: absolute;
        left: 0;
        z-index: 1;
        top: 10px;
    }

    &--switch-right-container {
        position: absolute;
        right: -150px;
        z-index: 1;
        bottom: 30px;
        width: 150px;
    }

    &--description {
        padding: 8px 8px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
        color: gray;
        display: none;

        @include breakpoints(sm) {
            display: block;
        }
    }

    &--icon-with-label {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}