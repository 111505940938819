.home {
  $el: &;

  &--tryon {
    display: block;
    width: 100%;

    @include breakpoints(sm) {
      display: none;
    }
  }

  &--tryon-open {
    z-index: 9999999999;
  }
}
