.drawer {
    $el: &;

    &--button {
        width: 100%;
        border: none !important;
    }

    &--container{
        height: calc(100vh - 41px);
        top: 41px;
        position: absolute;
    }

    &--content {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &--category-container {
        border-top: 1px solid rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        padding-top: 16px;
        gap: 16px;
    }
    &--button-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 16px;
    }

    &--header {
        font-size: 1.5rem;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    }

    &--close-button {
        position: absolute;
        right: 16px;

        > span {
            justify-content: flex-end !important;
        }
    }

    &--type-button {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 16px;
        font-size: 16px;

        &:first-child {
            margin-top: 16px;
        }
    }

    &--poweredby-mobile {
        color: rgba(0, 0, 0, .25);
        text-align: center;
        padding-bottom: 8px;
        font-size: 12px;

        a {
            text-decoration: underline;
            color: rgba(0, 0, 0, .25);
        }
    }
}

.ant-collapse-header {
    padding: 16px !important;
}

.ant-drawer-body {
    padding: 0;
}

.ant-drawer-content,
.ant-drawer-wrapper-body {
    background: white;
}