.collapse {
    $el: &;

    width: 100%;

    &--container {
        width: 100% !important;
        background: $background;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04), 0px 2px 4px rgba(96, 97, 112, 0.16);
        border-radius: 0px;
        border: none;
        overflow: hidden;
    }

    &--checkbox {
        width: 100%;
        padding-left: 8px;
    }

    .ant-collapse-arrow {
        right: 12px !important;
    }

    .ant-collapse-header {
        padding: 10px 11px !important;
    }

    .ant-collapse {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        width: 100%;
        font-weight: 500 !important;
        font-size: 14px;
        line-height: 24px;
        color: #1c1c1c;
        border: none !important;
    }

    .ant-collapse-content {
        border: none;
    }

    &--bubble-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0 10px;
    }

    &--bubble {
        background: #ffffff;
        box-shadow: 0px 0px 2px rgba(40, 41, 61, 0.04), 0px 4px 8px rgba(96, 97, 112, 0.16);
        border-radius: 24px;
        width: auto;
        padding: 6px 25px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.2px;

        color: #1c1c28;
    }

    &--checkbox-container {
        display: flex;
        flex-direction: column;
    }
}