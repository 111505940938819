.imagesmooth {
  $el: &;

  &--container {
    position: relative;
    overflow: hidden;
  }

  &--current, &--old, &--loader, &--loader-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &--current, &--old {
    object-fit: contain;
  }

  &--cover {
    img {
        object-fit: cover;
    }
  }

  &--loader {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  &--loader-bg {
    background-color: white;
    opacity: 0.3;
  }
}